<template>
  <div class="allmsgclass">
    <navigation :msg="msg"></navigation>
    <div class="topmsgclass">
      <div>请在提现前确认提现账户设置；</div>
      <div>
        如收款方(即“持卡人”)非事业合伙人本人，请联系收款方、确保能收到手机验证码。
      </div>
    </div>
    <div class="information">
      <div class="in1">
        <label for="ca1"><span>*</span>银行卡号</label>
        <input
          id="ca1"
          v-model="bank_card_id"
          placeholder="请正确输入银行账户"
        />
      </div>
      <div class="in1">
        <label for="ca2"><span>*</span>开户银行</label>
        <input id="ca2" v-model="bank" placeholder="请正确输入开户银行" />
      </div>
      <div class="in1">
        <label for="ca3"><span>*</span>持卡人</label>
        <input id="ca3" v-model="card_holder" placeholder="请正确输入持卡人" />
      </div>
      <div class="in1">
        <label for="ca4"><span>*</span>持卡人手机</label>
        <input id="ca4" v-model="phone" placeholder="请输入持卡人实名手机" />
      </div>
      <div class="in1">
        <label for="code"><span>*</span>手机验证码</label>
        <input id="code" v-model="code" placeholder="验证码" />
        <div class="getcodeclass" @click="cligetcode">
          {{ getcode == "获取验证码" ? getcode : `请${getcode}秒后重发` }}
        </div>
      </div>
    </div>
    <div @click="showqrcode = true" class="service">
      <div>企业账户提现请联系客服</div>
      <div><img src="../../assets/kefu.png" /></div>
    </div>
    <buttones class="thebu" :msg="bumsg"></buttones>
    <van-popup class="qrcoedclass" v-model="showqrcode">
      <div>
        <img src="../../assets/luobo2.png" />
      </div>
      <div>企业账户提现请扫码联系客服</div>
    </van-popup>
  </div>
</template>
<script>
import navigation from "../../components/navigation/index";
import buttones from "../../components/botton/index";
import { Dialog } from "vant";
import { Notify } from "vant";
import { Toast } from "vant";
var intervalID;

export default {
  components: {
    navigation,
    buttones,
  },
  data() {
    return {
      msg: "提现设置",
      bumsg: "保存",
      showqrcode: false,
      getcode: "获取验证码", //获取验证码
      phone: "", //持卡人手机
      bank_card_id: "", //银行卡号
      bank: "", //开户银行
      card_holder: "", //持卡人
      code: "", //验证码
    };
  },
  mounted() {
    //查询用户账户信息
    this.getuserinformation();
  },
  methods: {
    /**
     * 判断字符是否符合格式
     */
    ismsgtrue() {
      if (this.bank_card_id == "") {
        Notify({ type: "danger", message: "请输入银行卡号" });
        return;
      }
      if (this.bank == "") {
        Notify({ type: "danger", message: "请输入开户银行" });
        return;
      }
      if (this.card_holder == "") {
        Notify({ type: "danger", message: "请输入姓名" });
        return;
      }
      if (this.phone == "") {
        Notify({ type: "danger", message: "请输入手机号" });
        return;
      }
      if (this.code == "") {
        Notify({ type: "danger", message: "请输入验证码" });
        return;
      }
      return true;
    },
    /**
     * 点击保存按钮
     */
    clickmeth() {
      //判断是否符合格式
      if (!this.ismsgtrue()) return;
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      this.binduserinformation({
        reqType: "userinfo",
        mobile: this.phone,
        mobile_code: this.code,
        bank_card_id: this.bank_card_id,
        bank: this.bank,
        card_holder: this.card_holder,
      });
    },
    /**
     * 保存用户信息
     *
     */
    binduserinformation(obj) {
      this.$http.post("/firm/v1/Channel/bind_account_info", obj).then((res) => {
         
        this.$toast("绑定成功");
      });
    },
    /**
     * 查询用户绑定的信息
     */
    getuserinformation() {
      this.$http
        .post("/firm/v1/Channel/get_account_info", {
          reqType: "userinfo",
        })
        .then((res) => {
           
          let selectmsg = JSON.parse(res.data).data.account_info;
          this.phone = selectmsg.card_phone;
          this.card_holder = selectmsg.card_holder;
          this.bank_card_id = selectmsg.bank_card_id;
          this.bank = selectmsg.bank;
        });
    },
    /**
     * 计时器
     */
    timeing() {
      this.getcode = 60;
      intervalID = setInterval(() => {
        this.getcode--;
         
        if (this.getcode < 1) {
          clearInterval(intervalID);
          this.getcode = "获取验证码";
        }
      }, 1000);
    },

    /**
     * 停止计时器
     */
    destroyed() {
      clearInterval(intervalID);
    },

    /**
     * 获取验证码
     */
    cligetcode() {
      if (this.getcode != "获取验证码") {
        return;
      }
      if (this.phone == "") {
        Dialog({ message: "请输入正确手机号" });
        return;
      } else if (!/^1[3-9]\d{9}$/.test(this.phone)) {
        Dialog({ message: "请输入正确手机号" });
        return;
      }

       
      this.timeing();

      //获取验证码
      this.sendcode();
    },

    /**
     * 发送验证码
     */
    sendcode() {
      this.$http
        .post("/firm/v1/login/valid_send", {
          reqType: "login",
          mobile: this.phone,
          source: "currency",
        })
        .then((res) => {
           
          this.$toast(JSON.parse(res.data).msg);
        });
    },
  },
};
</script>
<style scoped>
.topmsgclass {
  font-size: .25rem;
  color: #141F33;
  line-height: .38rem;
  background-color: #FFF2ED;
  padding: .22rem .31rem;
}
input {
  width: 4.5rem;
}
span {
  color: #f55613;
  margin-right: 1px;
}
#code {
  text-align: center;
  max-width: 2rem;
}
.getcodeclass {
  color: #f55613;
}
.qrcoedclass {
  width: 5.44rem;
  height: 4.2rem;
  border-radius: 0.2rem;
  font-size: 0.28rem;
}
.qrcoedclass img {
  width: 1.8rem;
  height: 1.8rem;
}
.qrcoedclass > div:first-child {
  text-align: center;
  margin-top: 0.89rem;
}
.qrcoedclass > div:nth-child(2) {
  text-align: center;
  margin-top: 0.23rem;
}
.service {
  font-size: 0.36rem;
  display: flex;
  align-items: center;
  background-color: #fab291;
  padding: 0.28rem 0;
  margin: 1rem 0.32rem;
  justify-content: center;
  color: #ffffff;
}
.service img {
  width: 0.4rem;
  height: 0.4rem;
  margin-left: 0.27rem;
}
.thebu {
  margin-top: 2rem;
}
.allmsgclass {
  background-color: #ffffff;
  padding-bottom: 3rem;
}
.information {
  margin: 0.59rem 0.54rem;
  font-size: 0.28rem;
  color: #697386;
}
.in1 {
  padding: 0.22rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
input {
  text-align: right;
  border: none;
}
input::placeholder {
  color: #c1c5ce;
}
</style>